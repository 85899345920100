<template>
  <div class="info-content">
    <!-- <div class="card-title">
      <span class="line"></span>
      实时统计
      <label></label>
    </div>
    <div class="info-item" v-for="(val, key) in infoCountOption" :key="key">
      <div class="info-icon">
        <img :src="val.icon" />
      </div>
      <div class="info-count">
        <div class="content">
          <Number
            :style="{color:val.textColor}"
            :time="1"
            :value="val.num * 1"
          /> -->
    <!-- <span :style="{color:val.textColor}">{{val.num}}</span> -->
    <!-- </div>
        <div>
          {{ val.label }}
          <strong
            style="color:#5174B2"
          >{{ val.label=== "今日离线率" ? "(%)" : "(台)"}}</strong>
        </div>
      </div>
    </div> -->
    <ModelBg title="实时数据统计">
      <div style="height:100%;overflow: auto;" ref="real-box">
        <div class="box-item">
          <div v-for="(val, key) in infoCountOption"
            :key="key"
            :style="{ width: val.percent }"
            :class="key=='acticeRate'?'acticeRate':''">
            <img :src="val.icon"
              alt=""
              v-if="key!='acticeRate'"
              :style="{width:imgW+'px'}">
            <div>
              <div class="details">
                <div>
              <!-- <span>{{val.num}}</span> -->
                  <countTo :startVal='0' v-if="val.label == '离线率'" :ref="key" :autoplay="false" :endVal='(val.num ? Number(val.num) : 0)' separator="" :decimals="1" :duration='2500'></countTo>
                  <countTo :startVal='0' v-else :ref="key" :autoplay="false" :endVal='(val.num ? Number(val.num) : 0)' separator="" :duration='2500'></countTo>
                  {{ val.unit }}
                </div>
                <div>{{ val.label }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="box-details"
         >
          <div v-for="(val, key) in realDetails"
            :key="key">
            <div>
              <div>
                <!-- <span>{{val.num}}</span> -->
                <countTo :startVal='0' :ref="key" :autoplay="false" :endVal='Number(val.num)' separator="" :duration='2500'></countTo>{{ val.unit }}
              </div>
              <span>{{ val.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </ModelBg>
  </div>
</template>

<script>
// import Number from './Number';
import ModelBg from './components/ModelBg';
import countTo from 'vue-count-to';

export default {
  components: {
    // Number,
    ModelBg,
    countTo
  },
  props: {
    options: {
      type: Object,
      default: {}
    },
    oilOptions: {
      type: Object,
      default: {}
    },
    workOptions: {
      type: Object,
      default: {}
    },
  },
  watch: {
    options(val) {
      if (val) {
        this.getInfo(val);
      }
    },
    oilOptions(val) {
      if (val) {
        this.getOilInfo(val);
      }
    },
    workOptions(val) {
      if (val) {
        this.getWorkInfo(val);
      }
    }
  },
  data() {
    return {
      infoCountOption: {
        vehicleTotal: {
          icon: require('@/assets/images/bigScreen/online.png'),
          num: 0,
          unit: '台',
          label: "设备总数",
          percent: "34%"
        },
        offline: {
          icon: require('@/assets/images/bigScreen/count.png'),
          num: 0,
          unit: '台',
          label: "离线数",
          percent: "34%"
        },
        acticeRate: {
          num: 0,
          unit: '%',
          label: "离线率",
          percent: "32%"
        },
      },
      realDetails: {
        todayWorkingTime: {
          num: 0,
          unit: '小时',
          label: "今日工作时长",
        },
        todayOil: {
          num: 0,
          unit: '升',
          label: "当日总油耗",
        },
        totalWorkingTime: {
          num: 0,
          unit: '小时',
          label: "累计工作时长",
        },
        totalOil: {
          num: 0,
          unit: '升',
          label: "累计总油耗",
        }
      },
      imgW: 0,
    };
  },
  mounted() {
    //  this.getInfo(this.options, this.oilOptions);
  },

  methods: {
    getInfo(map) {
      let h = this.$refs['real-box'].offsetHeight / 2 * 0.6;
      let w = this.$refs['real-box'].offsetWidth / 2 * 0.28;
      this.imgW = w > h ? h : w;
      for (var key in map) {
        if (this.infoCountOption[key]) {
          this.infoCountOption[key].num = map[key];
          this.$refs[key][0].start()
        }
      }
    },
    getOilInfo(map) {
      for (var key in map) {
        if (this.realDetails[key]) {
          this.realDetails[key].num = map[key];
          this.$refs[key][0].start()
        }
      }
    },
    getWorkInfo(map) {
      for (var key in map) {
        if (this.realDetails[key]) {
          this.realDetails[key].num = parseInt(map[key] / 3600);
          this.$refs[key][0].start()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './DataCenterStyle.scss';

.info-item {
  width: 50%;
  display: flex;
  padding: 10% 0;
  .info-icon {
    width: 35%;
    padding: 0 9px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 72%;
    }
  }
  .info-count {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 25px;
    // .content {
    //   position: relative;
    //   writing-mode: vertical-lr;
    //   text-orientation: upright;
    //   & > label {
    //     position: absolute;
    //     top: 10px;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     letter-spacing: 10px;
    //   }
    // }
    & > div {
      &:nth-child(1) {
        color: #eab12b;
      }
      &:nth-child(2) {
        color: #fff;
        font-size: 12px;
      }
      & > span {
        font-size: 24px;
      }
    }
  }
}
.box-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  height: 68%;
  padding: 0 18px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 35%;
      max-width: 58px;
      max-height: 58px;
      margin-right: 0.1rem;
    }
    > div {
      > div {
        font-size: 0.16rem;
        color: #ffffff;
        text-align: center;
        > div {
          > span {
            font-size: 0.22rem;
            font-weight: bold;
            color: #f7981d;
            // margin-right: 0.1rem;
          }
        }
      }
    }
  }
  > .acticeRate {
    width: 32%;
    height: 100%;
    text-align: center !important;
    padding-top: 0px !important;
    background: url('../../../src/assets/images/bigScreen/huoyueRates.png')
      no-repeat center center;
    background-size: 64%;
    > div {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      font-size: 0.18rem;
      color: #ffffff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        margin-top: 0.1rem;
        > div {
          > span {
            font-size: 0.22rem;
            font-weight: bold;
            color: #f7981d;
            margin-left: 0.03rem;
            margin-right: 0.01rem;
          }
        }
      }
    }
  }
}
.box-details {
  height: 32%;
  padding: 0 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  padding-top: 6px;
  > div {
    width: 25%;
    > div {
      font-size: 0.18rem;
      color: #ffffff;
      text-align: center;
      > div {
        margin-bottom: 0.01rem;
        > span {
          font-size: 0.22rem;
          font-weight: bold;
          color: #f7981d;
          // margin-right: 0.1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1536px) {
  .info-item {
    padding: 0;
  }
}
</style>
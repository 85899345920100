import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);
/**
 * 车辆基本信息
 * @param {JSON} val 
 */
export const vehicleInfoCount = (val) => { return axios2.get('datavisual/nowVehilceState', { params: val }); };

/**
 * 过期和即将过期数量
 * @param {JSON} val 
 */
export const serviceExpirationVCount = (val) => { return axios.get('figuresController/serviceExpirationVCount.do', { params: val }); };

/**
 * 报警统计
 * @param {JSON} val 
 */
export const findAlarmState = () => { return axios.get('figuresController/findAlarmState.do'); };


/**
 * 授权记录查询过期和即将过期的车辆信息
 * @param {JSON} val 
 */
export const queryVehicleFreeLog = (val) => { return axios.get('figuresController/queryVehicleFreeLog.do', { params: val }); };

/**
 * 报警日TOP
 * @param {JSON} val 
 */
export const findAlarmTOP = (val) => { return axios2.get('datavisual/top10VehicleAlarmRank', { params: val }); };

/**
 * 报警月TOP
 * @param {JSON} val 
 */
export const fatigueGroupAlarmRank = (val) => { return axios2.get('datavisual/overSpeedAndFatigueGroupAlarmRank', { params: val }); };


/**
 * 主动安全报警
 * @param {JSON} val 
 */
export const findInitiativeAlarm = (val) => { return axios2.get('datavisual/todayAlarmDistribution', { params: val }); };


/**
 * 查询近三天报警情况
 * @param {JSON} val 
 */
export const findAlarmCountToThreeDay = (val) => { return axios2.get('datavisual/threeDayCount', { params: val }); };


/**
 * 今天在线
 * @param {JSON} val 
 */
export const onlineRateToDay = (val) => { return axios.get('figuresController/onlineRateToDay.do', { params: val }); };


/**
 * 7天在线
 * @param {JSON} val 
 */
export const onlineRateHebdomad = (val) => { return axios.get('figuresController/onlineRateHebdomad.do', { params: val }); };

/**
 * 地图热点分布
 * @param {JSON} val 
 */
export const hotSpotDistribution = (val) => { return axios.get('figuresController/hotSpotDistribution.do', { params: val }); };

/**
 * 查询实时在线率
 * @param {JSON} val 
 */
export const onlineRateToNew = (val) => { return axios.get('figuresController/onlineRateToNew.do', { params: val }); };

/**
 * 查询实时在线车辆
 * @param {*} val 
 */
export const getVheicleState = (val) => { return axios.get('monitor/getVheicleState.do', { params: val }); };

/**
 * 查询近半年加车状况
 * @param {JSON} val 
 */
export const findAddVehicleState = (val) => { return axios.get('figuresController/findAddVehicleState.do', { params: val }); };

/**
 * 查询车辆类型及其对应的数量
 * @param {JSON} val 
 */
export const findVehicleShape = (val) => { return axios.get('figuresController/findVehicleShape.do', { params: val }); };

/**
 * 查询日里程Top10Down10
 * @param {JSON} val 
 */
export const getMileageRank = (val) => { return axios.get('ztStHome/mileageRank', { params: val }); };

/**
 * 查询运营数据
 * @param {JSON} val 
 */
export const getTodaySummary = (val) => { return axios.get('ztStHome/todaySummary', { params: val }); };

/**
 * 查询飞线数据
 * @param {JSON} val 
 */
export const getVehicleFlyingLine = (val) => { return axios.get('indexPage/vehicleFlyingLine.do', { params: val }); };

/**
 * 查询概况统计
 * @param {JSON} val 
 */
export const getSummarySt = (val) => { return axios.get('ztStHome/getSummarySt', { params: val }); };

/* 
 * 获取运输车队趟数
 */
export const getTransport = (val) => { return axios.get('indexPage/transportVehiclesNumberTop10.do', { params: val }); };

/**
 * 获取车组重点报警排行
 */
export const getGroupAlarmRank = (val) => { return axios.get('ztStHome/vehicleGroupAlarmRank', { params: val }); };

/**
 * 渣土车首页报警分布
 */
export const getAlarmList = (val) => { return axios.get('ztStHome/getAlarmChart', { params: val }); };

/**
 * 趟程趋势数据
 */
export const getNumberDrift = (val) => { return axios.get('indexPage/numberDrift.do', { params: val }); };

/**
 * 工地出土量top10
 */
export const getSiteOutSoilTOP = (val) => { return axios.get('indexPage/siteOutSoilTop10.do', { params: val }); };

/**
 * 消纳场收土量top10
 */
export const getEarthInSoilTOP = (val) => { return axios.get('indexPage/earthInSoilTop10.do', { params: val }); };

/**
 * 车辆、场所统计
 */
export const getPlaceSummary = (val) => { return axios.get('ztStHome/getPlaceSummary', { params: val }); };

/**
 * 上传首页LOGO
 */
export const uploaduserlogophotos = (photoInfo) => {
   let data = new FormData();
   data.append('file', photoInfo.file);
   data.append('sorts', photoInfo.sorts);
   data.append('Id', photoInfo.id);
   photoInfo.uuid && data.append('uuid', photoInfo.uuid);
   return axios.post('user/uploaduserlogophotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 更新首页LOGO
 */
export const updateuserlogophotos = (photoInfo) => {
   let data = new FormData();
   data.append('file', photoInfo.file);
   data.append('sorts', photoInfo.sorts);
   data.append('Id', photoInfo.id);
   data.append('uuid', photoInfo.uuid);
   return axios.post('user/updateuserlogophotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 获取首页LOGO
 */
export const getuserlogophotos = (val) => { return axios.get('user/getuserlogophotos.do', { params: val }); };

//#region 大屏数据

/**区域报警TOP5  */
export const getAreaAlarmTop = (val) => { return axios2.get('dataCenter/getAreaAlarmTop', { params: val }); };

/**工时统计  */
export const getManHourStatistics = (val) => { return axios2.get('dataCenter/getManHourStatistics', { params: val }); };

/**产品线分类  */
export const getProductClassList = (val) => { return axios2.get('dataCenter/getProductClassification', { params: val }); };

/**产品种类  */
export const getProductTypes = (val) => { return axios2.get('dataCenter/getProductType', { params: val }); };

/**实时警报  */
export const getRealizeAlarm = (val) => { return axios2.get('dataCenter/getRealizeAlarm', { params: val }); };

/** 省份产品数量分布  */
export const getProductByProvince = (val) => { return axios2.get('dataCenter/getProductByProvince', { params: val }); };

export const getTodayAlarm = (val) => { return axios2.get('monitor/getTodayAlarm', { params: val }); };

/** 获取紧急报警 */
export const getVehicleOneKey = (val) => { return axios2.get('monitor/getVehicleOneKey.do', { params: val }); };

/** 获取倾覆报警  -- 修改 2023-2-6 */
export const getCapsizeAlarm = (val) => { return axios2.get('monitor/getCapsizeAlarm.do', { params: val }); };

/** 报警已知晓 */
export const alarmWhetherHandle = (params) => { return axios2.post('monitor/alarmWhetherHandle', params, { headers: { 'Content-Type': 'application/json' } }); };

/** 报警是否显示 */
export const alarmWhetherDisplayed = (val) => { return axios2.get('monitor/alarmWhetherDisplayed', { params: val }); };

/** 获取升级包信息 */
export const getTerminalUpgrade = (params) => { return axios.post('terminalUpgrade/getTerminalUpgrade', params, { headers: { 'Content-Type': 'application/json' } }); };

/** 添加升级包信息 */
export const addTerminalUpgrade = (params) => {
   let data = new FormData();
   data.append('packFile', params.packFile);
   data.append('packName', params.packName);
   data.append('packVersion', params.packVersion);
   data.append('packHardwareVersion', params.packHardwareVersion);
   data.append('packFirmwareVersion', params.packFirmwareVersion);
   data.append('manufacturers', params.manufacturers);
   return axios.post('terminalUpgrade/addTerminalUpgrade', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/** 删除升级包信息 */
export const delTerminalUpgrade = (params) => { return axios.post('terminalUpgrade/delTerminalUpgrade', params, { headers: { 'Content-Type': 'application/json' } }); };

/** 修改升级包信息  */
export const updateTerminalUpgrade = (params) => {
   let data = new FormData();
   data.append('packFile', params.packFile);
   data.append('packHardwareVersion', params.packHardwareVersion);
   data.append('packFirmwareVersion', params.packFirmwareVersion);
   data.append('manufacturers', params.manufacturers);
   data.append('id', params.id);
   return axios.post('terminalUpgrade/updateTerminalUpgrade', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/** 升级任务信息查询  */
export const getTerminalGradeTaskList = (params) => { return axios.post('terminalUpgradeTask/getTerminalGradeTaskList', params, { headers: { 'Content-Type': 'application/json' } }); };

/** 删除升级任务 */
export const delUpgradeTask = (val) => { return axios.get('terminalUpgradeTask/delBatch', { params: val }); };

/** 添加升级任务  */
export const addUpgradeTask = (params) => { return axios.post('terminalUpgradeTask/add', params, { headers: { 'Content-Type': 'application/json' } }); };

/** 修改升级任务  */
export const updateTask = (params) => { return axios.post('terminalUpgradeTask/updateTask', params, { headers: { 'Content-Type': 'application/json' } }); };

/** 执行升级任务  */
export const execUpgrade = (params) => { return axios.post('terminalUpgradeTask/execUpgrade', params); };

/** 升级历史记录查询  */
export const getHistoryList = (params) => { return axios.post('terminalUpgradeTask/getTerminalGradeHistoryList', params, { headers: { 'Content-Type': 'application/json' } }); };

//#endregion
<template>
  <div>
    <el-carousel indicator-position="none" height="100%" arrow="always" class="max-height" >
      <el-carousel-item v-for="item in maxNum" :key="item">
        <div class="video-box">
          <div v-if="Datas[(item-1) * 4]">
            <el-popover
              placement="top-start"
              :close-delay="80" popper-class="popover-box"
              :title="Datas[(item-1) * 4].plate"
              trigger="hover"
              :content="Datas[(item-1) * 4].alarmTypeChn">
              <div class="video-item-box" slot="reference">
                <div class="play-img" @click="play(Datas[(item-1) * 4])">
                  <img src="../../../assets/images/播放.png" />
                </div>
                <img class="videoImg" :src="Datas[(item-1) * 4].imagePath" />
              </div>
            </el-popover>
          </div>
          <div v-if="Datas[((item-1) * 4) + 1]">
            <el-popover
              placement="top-start"
              :close-delay="80" popper-class="popover-box"
              :title="Datas[((item-1) * 4) + 1].plate"
              trigger="hover"
              :content="Datas[((item-1) * 4) + 1].alarmTypeChn">
              <div class="video-item-box" slot="reference">
                <div class="play-img" @click="play(Datas[((item-1) * 4) + 1])">
                  <img src="../../../assets/images/播放.png" />
                </div>
                <img class="videoImg" :src="Datas[((item-1) * 4) + 1].imagePath" />
              </div>
            </el-popover>
          </div>
          <div v-if="Datas[((item-1) * 4) + 2]">
            <el-popover
              placement="bottom-start"
              :close-delay="80" popper-class="popover-box"
              :title="Datas[((item-1) * 4) + 2].plate"
              trigger="hover"
              :content="Datas[((item-1) * 4) + 2].alarmTypeChn">
              <div class="video-item-box" slot="reference">
                <div class="play-img" @click="play(Datas[((item-1) * 4) + 2])">
                  <img src="../../../assets/images/播放.png" />
                </div>
                <img class="videoImg" :src="Datas[((item-1) * 4) + 2].imagePath" />
              </div>
            </el-popover>
          </div>
          <div v-if="Datas[((item-1) * 4) + 3]">
            <el-popover
              placement="bottom-start"
              :close-delay="80" popper-class="popover-box"
              :title="Datas[((item-1) * 4) + 3].plate"
              trigger="hover"
              :content="Datas[((item-1) * 4) + 3].alarmTypeChn">
              <div class="video-item-box" slot="reference">
                <div class="play-img" @click="play(Datas[((item-1) * 4) + 3])">
                  <img src="../../../assets/images/播放.png" />
                </div>
                <img class="videoImg" :src="Datas[((item-1) * 4) + 3].imagePath" />
              </div>
            </el-popover>
          </div>
          <!-- <div v-if="Datas[((item-1) * 4) + 1]">
            <div class="play-img" @click="play(Datas[((item-1) * 4) + 1])">
              <img src="../../../assets/images/播放.png" />
            </div>
            <img class="videoImg" :src="Datas[((item-1) * 4) + 1].imagePath" />
            <div class="a-type">{{ Datas[((item-1) * 4) + 1].alarmTypeChn }}</div>
          </div>
          <span v-else></span>
          <div v-if="Datas[((item-1) * 4) + 2]">
            <div class="play-img" @click="play(Datas[((item-1) * 4) + 2])">
              <img src="../../../assets/images/播放.png" />
            </div>
            <img class="videoImg" :src="Datas[((item-1) * 4) + 2].imagePath" />
            <div class="a-type">{{ Datas[((item-1) * 4) + 2].alarmTypeChn }}</div>
          </div>
          <span v-else></span>
          <div v-if="Datas[((item-1) * 4) + 3]">
            <div class="play-img" @click="play(Datas[((item-1) * 4) + 3])">
              <img src="../../../assets/images/播放.png" />
            </div>
            <img class="videoImg" :src="Datas[((item-1) * 4) + 3].imagePath" />
            <div class="a-type">{{ Datas[((item-1) * 4) + 3].alarmTypeChn }}</div>
          </div>
          <span v-else></span> -->
        </div>
      </el-carousel-item>
    </el-carousel>
    <Dialog
      :value="dialogVisible"
      width="700px"
      height="auto"
      :showFooter="false"
      @close="closeClick"
      :appendToBody="false"
    >
      <!-- 标题 -->
      <template #title>
        <span>{{groupName}}</span>
        <span style="margin:0 10px;">-</span>
        <span>{{plate}}</span>
      </template>

      <div class="mainCls" style="width: 100%">
        <video
          :src="videoPath"
          type="video/mp4"
          width="100%"
          v-if="dialogVisible"
          controls
          autoplay
          class="video"
          ref="dialogVideo"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
export default {
  props: {
    Datas: { //table 数据
      type: Array,
      default: []
    },
  },
  components: { Dialog },
  data() {
    return {
      maxNum: 0,
      dialogVisible: false,
      videoPath: '',
      plate: '', // 车牌
      groupName: '', // 所属车组
    };
  },
  watch: {
    Datas: {
      handler(v) {
        if (v.length) {
          this.maxNum = Math.ceil((v.length / 4));
        }

      },
      deep: true,
    immediate: true
    }
  },
  methods: {
    // 关闭模态框
    closeClick() {
      this.dialogVisible = false;
    },

    /** 附件播放事件 */
    play(item) {
      this.videoPath = item.aviPath;
      this.plate = item.plate;
      this.groupName = item.groupName;
      this.dialogVisible = true;
    },

  }
}
</script>
<style lang="scss">
  .popover-box {
    padding: 8px !important;
    color: rgba(247, 152, 29, 1);
  }
</style>
<style lang="scss" scoped>
.max-height {
  height: 100%;
}
::v-deep .el-carousel__indicators {
  display: none;
}
.video-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 5% 10% 0;
  height: 100%;
  > div {
    width: 34%;
    height: 40%;
  }
  .video-item-box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .videoImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mainCls {
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 100%;
    }

    .play-img {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -16px;
      margin-top: -16px;
      cursor: pointer;
      z-index: 12;
    }
    span {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  ::v-deep .el-popover__reference-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
</style>